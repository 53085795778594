.BookInBasket {
    background-color: #FFFFFF;
    height: 300px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(207, 104, 104, 0.2);
    transition: 0.3s;
}
@media all and (max-width:1024px) {
    .BookInBasket {
        width: 80%;
    }
}
@media all and (max-width:768px) {
    .BookInBasket {
        height: 300px;
        width: 90%;
        margin-top: 10%;
    }
}
@media all and (max-width:425px) {
    .BookInBasket {
        height: 200px;
        width: 90%;
        margin-top: 10%;
    }
}
.CartIcon {
    right: 4%;
    bottom: 3%;
    color:grey;
    position: absolute;
    font-size: 300%;
    cursor: pointer;
    transition: 1s;
}
.CartIcon:hover {
    color: black;
}
.BookInBasket:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.BookCover {
    background-color: #FAFAFA;
    height: 70%;
    width: auto;
    max-width: 30%;
    position: absolute;
    top: -8%;
    left: 3%;
    text-align: center;
    cursor: pointer;
}
.BookCover img {
    min-height: 180px;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.BookInfoInBasket {
    margin-top: 3.5%;
    background-color: #FFFFFF;
    height: 70%;
    width: 50%;
    position: absolute;
    left: 32%;
}
@media all and (max-width:768px) {
    .BookInfoInBasket {
        width: 70%;
        left: 30%;
    }
}
.BookInfoInBasket h1 {
    color: #263238;
    margin: auto;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 2%;
    height: 20%;
    width: 100%;
    cursor: pointer;
}
@media all and (max-width:768px) {
    .BookInfoInBasket h1 {
        font-size: 1.1em;
        width: 90%;
    }
}
.BookInfoInBasket h3 {
    color: #515280;
    margin: auto;
    font-size: 1.25em;
    height: 20%;
    width: 100%;
    cursor: pointer;
}
@media all and (max-width:768px) {
    .BookInfoInBasket h3 {
        font-size: 1em;
        width: 90%;
    }
}
.BookInfoInBasket h4 {
    margin-top: 4%;
    margin-right: 5%;
    font-size: 1.25em;
    text-align: right;
}
@media all and (max-width:768px) {
    .BookInfoInBasket h4 {
        font-size: 0.8em;
        margin-top: 10%;
    }
}
.BookInfoInBasket .FullPrice {
    font-size: 1.5em;
    text-align: center;
}
@media all and (max-width:768px) {
    .BookInfoInBasket .FullPrice {
        font-size: 1.2em;
    }
}
.Author {
    margin: auto;
    color:#607D8B;
    font-size: 1em;
}

.BookCountInBasket {
    position: absolute;
    right: 1%;
    bottom: 15%;
    width: 10%;
}
@media all and (max-width:768px) {
    .BookCountInBasket {
        width: 20%;
        bottom: 5%;
        right: 0%;
    }
}

.MakeZeroButton {
    position: absolute;
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    background-color: #E52723;
    border-radius: 0.5em;
    border-style: hidden;
    right: 1%;
    top: 2%;
    cursor: pointer;
    transition: 0.3s;
}
.MakeZeroButton:hover {
    background-color: red;
}
@media all and (max-width:768px) {
    .MakeZeroButton {
        font-size: 0.8em;
    }
}

.handle-counter {
    overflow: hidden;
}

.handle-counter .counter-minus,  .handle-counter .counter-plus,  .handle-counter input {
  text-align: center;
}

.handle-counter input {
  width: 50%;
  height: 10%;
  font-size: 1em;
  /* border-style: solid;
  border-color: #009dda;
  border-width: 1px; */
}

.btn {
  border: 1px solid transparent;
  height: 10%;
  width: 54%;
  font-size: 1em;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.btn:disabled, .btn:disabled:hover {
  background-color: darkgrey;
  cursor: not-allowed;
}

.btn-primary {
    background-color: #4250AE;
}

.btn-primary:hover, .btn-primary:focus {
    background-color: #4250AE;
}