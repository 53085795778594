.BookBasketTitle {
    width: 50%;
    height: 50%;
    margin: auto auto;
    display: flex;
}
@media all and (max-width:768px) {
    .BookBasketTitle {
        width: 70%;
    }
}

.BookBasketTitle .BookBasketWonImg {
    height: 100px;
    margin-left: 30%;
}
@media all and (max-width:768px) {
    .BookBasketTitle .BookBasketWonImg {
        height: 60px;
        margin-left: 10%;
        max-width: 25%;
    }
}

.BookBasketTitle h1 {
    margin-top: 5%;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: auto;
}
@media all and (max-width:768px) {
    .BookBasketTitle h1 {
        font-size: 1.5em;
    }
}

.BookBasketTotalCount {
    text-align: right;
    margin-right: 10%;
    font-weight: bold;
}
.BookBasketSummary {
    margin-left: auto;
    margin-right: auto;
    height: 5%;
    width: 60%;
    background-color: rgb(214, 214, 214);
    font-size: 2em;
    font-weight: bold;
    border-radius: 2em;
    margin-top: 2%;
    margin-bottom: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}
@media all and (max-width:768px) {
    .BookBasketSummary {
        font-size: 1.2em;
        margin-top: 5%;
        margin-bottom: 5%;
        border-radius: 0.5em;
        width: 80%;
    }
}
.BookBasketSummary .OriginalPrice {
    color: gray;
}

#OrderButton {
    width: 55%;
    height: 50px;
    font-size: x-large;
}

.NoResultBasket {
    font-size: 1.5em;
    font-weight: 350;
}
@media all and (max-width:768px) {
    .NoResultBasket {
        font-size: 1em;
    }
}
