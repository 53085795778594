.Book {
    background-color: #FFFFFF;
    height: 300px;
    width: 45%;
    margin: 3% 1.5%;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}
@media all and (max-width:768px) {
    .Book {
        width: 95%;
        margin-top: 10%;
    }
}
.icon {
    color:#D32F2F;
    position: absolute;
    right: 3px;
    top: 3px;
    cursor: pointer;
    transition: 1s;
}
.Book:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.BookCover {
    background-color: #FAFAFA;
    height: 70%;
    width: auto;
    max-width: 30%;
    position: absolute;
    top: -8%;
    left: 3%;
    text-align: center;
    cursor: pointer;
    z-index: 1;
}
@media all and (max-width:768px) {
    .BookCover {
        margin-top: 8%;
    }
}

.BookCover img {
    min-height: 180px;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.BookInfo {
    margin-top: 3.5%;
    background-color: #FFFFFF;
    height: 70%;
    width: 70%;
    position: absolute;
    left: 30%;
}
.BookInfo h1 {
    color: #263238;
    margin: auto;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 2%;
    height: 20%;
    width: 100%;
    cursor: pointer;
}
@media all and (max-width:768px) {
    .BookInfo h1 {
        font-size: 1.1em;
        width: 90%;
    }
}
.BookInfo h3 {
    color: #515280;
    margin: auto;
    font-size: 1.25em;
    height: 10%;
    width: 100%;
    cursor: pointer;
}
@media all and (max-width:768px) {
    .BookInfo h3 {
        font-size: 1em;
        width: 90%;
    }
}
.BookInfo .BookCompilerLine {
    margin-top: 4%;
    margin-right: 5%;
    margin-bottom: 0%;
    font-size: 1.25em;
    text-align: right;
}
@media all and (max-width:768px) {
    .BookInfo .BookCompilerLine {
        font-size: 0.8em;
        margin-top: 8%;
    }
}
.BookInfo .BookAuthorLine {
    margin-top: 2%;
    margin-right: 5%;
    margin-bottom: 0%;
    font-size: 1.25em;
    text-align: right;
}
@media all and (max-width:768px) {
    .BookInfo .BookAuthorLine {
        font-size: 0.8em;
        margin-top: 5%;
    }
}
.BookInfo p {
    font-size: 1.25em;
}
.BookDescription {
    color: #607D8B;
    font-size: 0.8em;
    margin-left: 8%;
    margin-right: 2%;
    text-align: justify;
}
@media all and (max-width:768px) {
    .BookDescription {
        font-size: 0.6em;
    }
}
@media all and (max-width:768px) {
    .BookListCart {
        position: absolute;
        width: 150%;
        bottom: 3%;
        right: 1%;
    }
}

.NotAcceptable {
    position: absolute;
    bottom: 0%;
    right: 2%;
    border-style: none;
    border-radius: 0.7em;
    border-width: 0.1em;
    padding: 8px;
    background-color: #FFD27C;
    color: black;
    text-align: center;
    font-size: 1em;
    cursor: pointer;
}
@media all and (max-width:768px) {
    .NotAcceptable {
        bottom: 7%;
        right: 5%;
        font-size: 0.8em;
        padding: 6px;
    }
}
.NotAcceptable:hover {
    background-color: rgb(255, 187, 61);
}
.NotVisible {
    position: absolute;
    bottom: 0%;
    right: 5%;
    border-style: solid;
    border-radius: 0.5em;
    border-width: 0.1em;
    padding: 5px;
    background-color: #D32F2F;
    color: white;
    text-align: center;
}
