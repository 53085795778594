header {
    background-color: #FAFAFA;
    height: 14vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media all and (max-width:768px) {
    header {
        display: inline;
        height: 10vh;
    }
}

.Title {
    background-color: #FAFAFA;
    height: inherit;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 1%;
}
