.BookDetailUpper {
    background-color: #FAFAFA;
    height: 50%;
    width: 100%;
    position: relative;
    transition: 0.3s;
    float: left;
    margin-bottom: 8%;
}
@media all and (max-width:768px) {
    .BookDetailUpper {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 5%;
        margin-bottom: 0%;
    }
}
.BookCoverStand {
    height: 100%;
    width: 100%;
    max-width: 40%;
    position: absolute;
    top: 5%;
    left: 2%;
    text-align: center;
}
@media all and (max-width:768px) {
    .BookCoverStand {
        margin-top: 5%;
        height: 50%;
        width: 95%;
        max-width: 100%;
        position: relative;
    }
}
.BookCoverStand img {
    min-height: 90%;
    max-width: 90%;
    vertical-align: middle;
    align-self: center;
    border-radius: 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    float: left;
}
@media all and (max-width:768px) {
    .BookCoverStand img {
        min-height: 100%;
        max-width: 100%;
    }
}

.BookDetailInfo {
    background-color: #FAFAFA;
    height: 80%;
    width: 70%;
    float: right;
    margin-top: 4%;
    line-height: 100%;
}
@media all and (max-width:768px) {
    .BookDetailInfo {
        width: 100%;
    }
}
.BookDetailInfo #title {
    font-size: 2.5em;
    width: 100%;
    text-align: center;
}
@media all and (max-width:768px) {
    .BookDetailInfo #title {
        font-size: 1.5em;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
.BookDetailInfo #subtitle {
    font-size: 1.5em;
    width: 100%;
    color: #515280;
    text-align: center;
}
@media all and (max-width:768px) {
    .BookDetailInfo #subtitle {
        font-size: 0.9em;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
.BookDetailInfo #compilers {
    margin-top: 5%;
    color:#000000;
    font-size: 1.5em;
    text-align: center;
}
@media all and (max-width:768px) {
    .BookDetailInfo #compilers {
        font-size: 0.9em;
    }
}
.BookDetailInfo #authors {
    color:#000000;
    font-size: 1.5em;
    text-align: center;
}
@media all and (max-width:768px) {
    .BookDetailInfo #authors {
        font-size: 0.9em;
    }
}
.BookDetailInfoList {
}
.BookDetailInfoList .InfoItem {
    width: 50%;
    margin-top: 3%;
    margin-left: 23%;
    align-items: center;
    float: left;
}
@media all and (max-width:768px) {
    .BookDetailInfoList .InfoItem {
        width: 90%;
        margin-top: 3%;
        margin-left: 10%;
        font-size: 0.8em;
    }
}
.BookDetailInfoList .LItem {
    display: inline-block;
    font-size: 1em;
    text-align: left;
    margin-right: 20%;
    float: left;
}
.BookDetailInfoList .RItem {
    display: inline-block;
    text-align: right;
    font-weight: bold;
    margin-left: 10%;
}
.DetailCart {
    position: absolute;
    width: 50%;
    right: 5%;
    bottom: 0%;
}
@media all and (max-width:768px) {
    .DetailCart {
        position: absolute;
        width: 150%;
        right: 5%;
        bottom: 0%;
     }
}

.BookDetailYouTubeTitle {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1%;
}
@media all and (max-width:768px) {
    .BookDetailYouTubeTitle {
        font-size: 1.2em;
    }
}
.BookDetailYouTube {
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    margin-bottom: -5%;
}
@media all and (max-width:768px) {
    .BookDetailYouTube {
        max-width: 95%;
    }
}

.BookDescriptions {
    margin-top: 5%;
    color: rgb(0, 0, 0);
    font-size: 1.1em;
    margin-left: 10%;
    margin-right: 10%;
    text-align: justify;
    float: left;
    line-height: 180%;
}
@media all and (max-width:768px) {
    .BookDescriptions {
        font-size: 0.9em;
     }
}

.DetailNotAcceptable {
    position: absolute;
    bottom: -20%;
    right: 2%;
    border-style: solid;
    border-radius: 0.7em;
    border-width: 0.1em;
    padding: 8px;
    background-color: #8AB1DB;
    color: white;
    text-align: center;
    font-size: 1em;
    cursor: pointer;
}
@media all and (max-width:768px) {
    .DetailNotAcceptable {
        bottom: 0%;
        font-size: 0.8em;
        padding: 6px;
    }
}
.DetailNotVisible {
    position: absolute;
    bottom: -7%;
    right: 5%;
    border-style: solid;
    border-radius: 0.7em;
    border-width: 0.1em;
    padding: 10px;
    background-color: #D32F2F;
    color: white;
    text-align: center;
    font-size: 1.2em;
}
@media all and (max-width:768px) {
    .DetailNotVisible {
        bottom: 0%;
    }
}
