.WholeFooter {
    margin-top: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.FooterLine {
    width: 90%;
}

footer {
    background-color: #FAFAFA;
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 2%;
}
@media all and (max-width:768px) {
    footer {
        height: 30vh;
    }
}

.FooterInfo {
    display: flex;
    width: 100%;
}

.FooterButtons {
    margin-left: 15%;
    display: flex;
    flex-direction: column;
    width: 50%;
}
@media all and (max-width:768px) {
    .FooterButtons {
        margin-top: 8%;
        margin-left: 5%;
        margin-right: auto;
        margin-bottom: -5%;
    }
}


.FooterDetailInfo {
    text-align: right;
    padding-right: 15%;
    line-height: 2em;
    font-size: 1em;
}
@media all and (max-width:768px) {
    .FooterDetailInfo {
        line-height: 1.5em;
        font-size: 0.8em;
        padding-right: 5%;
    }
}

.ButtonGap {
    margin-top: 3%;
}