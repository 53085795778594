.NavigationPanel {
    height: 6vh;
    display: flex;
    flex-direction: row;
    border-bottom: 0.5px solid #CFD8DC;
}

.SearchBar {
    background-color: #FAFAFA;
    font-size: 16px;
    height: inherit;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
}
@media all and (max-width:768px) {
    .SearchBar {
        margin-left: 2%;
        font-size: 10px;
        flex: 0.8;
    }
}

.NavBar {
    height: inherit;
    flex: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}