.CategoryButton {
    background-color: #FAFAFA;
    color: #78909C;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: bold;
    flex: 1;
    height: 100%;
}
@media all and (max-width:768px) {
    .CategoryButton {
        font-size: 15px;
    }
}
@media all and (max-width:425px) {
    .CategoryButton {
        font-size: 10px;
    }
}
.CategoryButtonActive {
    border-bottom: 2px solid #7EC7C5;
    transition: 0.3s;
}
