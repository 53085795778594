.NewBookTitleModal {
    margin-top: 1%;
}

.EventNoti {
    text-align: center;
}

.NewBookStandModal {
    padding: 3%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -7%;
}
.NewBookStandModal img {
    min-height: 400px;
    max-width: 530px;
    vertical-align: middle;
    align-self: center;
    border-radius: 1.5em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
}
@media all and (max-width:768px) {
    .NewBookStandModal img {
        min-height: 225px;
        max-width: 300px;
    }
}

.EventDescriptionModal {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 1em;
    line-height: 2em;
}
@media all and (max-width:768px) {
    .EventDescriptionModal {
        font-size: 0.8em;
     }
}

.NewBookTitles {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 1.2em;
    line-height: 2em;
}
@media all and (max-width:768px) {
    .NewBookTitles {
        font-size: 1em;
     }
}

.NewBookInfos {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -5%;
    text-align: left;
    font-size: 1em;
    line-height: 2em;
}
@media all and (max-width:768px) {
    .NewBookInfos {
        font-size: 0.8em;
     }
}

.ModalYouTube {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    margin-bottom: 5%;
}

.CenterLineModal {
    width: 90%;
}
@media all and (max-width:768px) {
    .CenterLineModal {
        width: 80%;
     }
}
