.User {
  background-color: #FAFAFA;
  height: inherit;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width:768px) {
  .User {
    display: block;
    width: 100%;
  }
}
.User .UserNameButton {
  font-size: 1.2em;
  cursor: pointer;
  font-weight: bold;
}
@media all and (max-width:768px) {
  .User .UserNameButton {
      font-size: 10px;
  }
}

.UserButtons {
  /* display: flex; */
  position: absolute;
  top: 2%;
  right: 3%;
}
@media all and (max-width:768px) {
  .UserButtons {
    position: relative;
    margin-left: 7%;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
  }
}

.BookStoreLinkButtons {
  /* display: flex; */
  position: absolute;
  top: 2%;
  left: 3%;
}
@media all and (max-width:768px) {
  .BookStoreLinkButtons {
    position: relative;
    margin-left: auto;
    margin-right: 5%;
    align-items: center;
    justify-content: center;
  }
}

.UserPictire {
  height: 6vh;
  width: 6vh;
  border: 1px solid #FAFAFA;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.UserPictire img {
  height: 80%;
  vertical-align: middle;
  cursor: pointer;
}
.BasketCount {
  background-color: #E52723;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  z-index: 13;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  visibility: visible;
  margin-right: 5%;
}
